import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import "./PodcastItem.scss";

function PodcastItem({ style, href, src, alt, recommended }) {
  const imgRef = useRef(null);
  const isVisible = useIntersectionObserver(imgRef, {
    threshold: 0.1,
    rootMargin: "100px",
  });

  return (
    <a
      className="podcast-item"
      href={href}
      rel="noreferrer noopener"
      target="_blank"
      style={style}
    >
      <img
        ref={imgRef}
        src={isVisible ? src : undefined}
        alt={alt}
        title={alt}
        style={{ minHeight: "50px" }} // Varmista, että elementillä on korkeus ennen kuvan lataamista
      />

      {recommended && (
        <span className="recommended">
          <StarIcon />
        </span>
      )}
    </a>
  );
}

export default PodcastItem;

function useIntersectionObserver(elementRef, options) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Aseta näkyväksi pysyvästi, kun elementti on kerran tullut näkyviin
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Voit lopettaa tarkkailun, kun kuva on ladattu
        }
      },
      {
        root: options.root,
        rootMargin: options.rootMargin,
        threshold: options.threshold,
      }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [elementRef, options.threshold, options.root, options.rootMargin]);

  return isVisible;
}
